import React from "react"
import { Link } from 'gatsby'
import cloudimage from "../../assets/images/banners/service-images/cloud.jpg"

import recovery from "../../assets/images/banners/icon-pack/data.png"
import aws from "../../assets/images/banners/icon-pack/cloud.png"
import amazon from "../../assets/images/banners/icon-pack/cloud-service.png"
import faster from "../../assets/images/banners/icon-pack/recovery.png"


const CloudAdoption = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img cloud"
          data-bg="assets/images/banners/service-banners/cloud.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Cloud Adoption</h1>
                  <h5 className="font-weight--normal text-white">
                    Aamanto will support you with our in-depth cloud engineering
                    expertise and years of experience modernizing legacy
                    solutions and developing innovative applications for
                    companies ranging from small businesses to large
                    enterprises.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
             <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our IT Service</h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                <div className="v1-dt-box-0-text">
                  <h2 className="heading">Cloud <span className="text-color-primary">Adoption</span></h2>
                  Aamanto will support you with our in-depth cloud
                  engineering expertise and years of experience
                  modernizing legacy solutions and developing innovative
                  applications for companies ranging from small
                  businesses to large enterprises, whether you are
                  already in the Cloud, having considered Cloud
                  Migration, or looking to develop a Cloud-Native
                  application. We thrive in private, hybrid, and public
                  cloud systems, assisting our customers in the
                  development of high-performance, scalable, and
                  reliable solutions that maximize the return on cloud
                  projects, reduce costs and minimize risk.
                </div>
              </div>

              <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                <div className="v1-dt-box-0-img">
                  <img className="img-fluid" src={cloudimage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

             <!--===========  feature-icon-wrapper  Start =============--> */}
        <div className="feature-icon-wrapper bg-gray section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Features of <span className="text-color-primary">Our Services Include</span></h2>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12">
                <div className="feature-list__three">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-shield-check"></i>
                              </div>
                              <h5 className="heading">Recovery and Backup</h5>
                            </div>
                            <div className="content">
                              <div
                                className="text"
                                style={{ textAlign: "justify" }}
                              >
                                When it comes to cloud backup and recovery, our
                                solution takes care of your company's Amazon Web
                                Services (AWS) data. This involves providing a
                                secure, scalable, and extremely durable storage
                                solution that eliminates the need for on-premise
                                infrastructure.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-lock-alt"></i>
                              </div>
                              <h5 className="heading">AWS Cloud Databases</h5>
                            </div>
                            <div className="content">
                              <div
                                className="text"
                                style={{ textAlign: "justify" }}
                              >
                                The AWS cloud database services are
                                comprehensive enough to fulfill your company's
                                application needs. Amazon Aurora, Amazon
                                DynamoDB, Amazon Relational Database Service
                                (Amazon RDS), Amazon Redshift, and Amazon
                                ElastiCache are among the services offered. Our
                                services manage everything.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-magnet"></i>
                              </div>
                              <h5 className="heading">Amazon CloudFront</h5>
                            </div>
                            <div className="content">
                              <div
                                className="text"
                                style={{ textAlign: "justify" }}
                              >
                                Amazon CloudFront is a global content delivery
                                network (CDN) service that provides highly
                                secure distribution of data, videos,
                                applications, and APIs. Our professionals are
                                dedicated to ensuring that CloudFront runs
                                smoothly for you.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-medal"></i>
                              </div>
                              <h5 className="heading">
                                Faster Problem Recovery
                              </h5>
                            </div>
                            <div className="content">
                              <div
                                className="text"
                                style={{ textAlign: "justify" }}
                              >
                                Enterprises increasingly want cloud-based
                                disaster recovery for their essential IT systems
                                that operate without disrupting workflow in the
                                case of a disaster. The AWS cloud simulates a
                                successful disaster recovery scenario, and we
                                guide businesses through the entire process.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div class="v1-box-2-parent">
              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={recovery} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Recovery and Backup</h5>
                            <div className="v1-text-2">
                              When it comes to cloud backup and recovery, our solution takes care of your company's Amazon Web Services (AWS) data. This involves providing a secure, scalable, and extremely durable storage solution that eliminates the need for on-premise infrastructure.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={aws} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">AWS Cloud Databases</h5>
                            <div className="v1-text-2">
                              The AWS cloud database services are comprehensive enough to fulfill your company's application needs. Amazon Aurora, Amazon DynamoDB, Amazon Relational Database Service (Amazon RDS), Amazon Redshift, and Amazon ElastiCache are among the services offered. Our services manage everything.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={amazon} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Amazon CloudFront</h5>
                            <div className="v1-text-2">
                              Amazon CloudFront is a global content delivery network (CDN) service that provides highly secure distribution of data, videos, applications, and APIs. Our professionals are dedicated to ensuring that CloudFront runs smoothly for you.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={faster} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Faster Problem Recovery</h5>
                            <div className="v1-text-2">
                              Enterprises increasingly want cloud-based disaster recovery for their essential IT systems that operate without disrupting workflow in the case of a disaster. The AWS cloud simulates a successful disaster recovery scenario, and we guide businesses through the entire process.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            </div>

          </div>
        </div>
        {/* <!--===========  feature-icon-wrapper  End =============-->

            <!--=========== About Company Area End ==========-->
            
            <!--========= More Services Start ===========--> */}
        <div className="about-delivering-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Process of <span className="text-color-primary">Cloud Adoption Services</span></h2>
                  <p>
                    We use a four-step process to swiftly and safely move your
                    data center to an Azure, AWS, or GCP cloud-based solution.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Data Archiving </h5>
                      <div className="desc">
                        Are you thinking about archiving your data? You might be
                        able to use services like Amazon Glacier or Amazon
                        Simple Storage Service in your cloud. Our experts will
                        assist you in making the best decision for your
                        company's needs.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title"> Execution </h5>
                      <div className="desc">
                        We develop a complete migration plan that ensures a
                        smooth transition, with minimal disruption to your core
                        company, by managing your infrastructure with tried and
                        true DevOps approaches and practices.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="delivering-optimal-wrap">
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Optimisation </h5>
                      <div className="desc">
                        We use the optimization phase to think about
                        geo-specific pricing, services, and data storage, as
                        well as scaling and containerizing your important
                        applications. This provides you with the best suitable
                        Cloud-based solution for your requirements.
                      </div>
                    </div>
                  </div>
                  <div className="list-item">
                    <div className="marker"></div>
                    <div className="title-wrap">
                      <h5 className="title">Assisting </h5>
                      <div className="desc">
                        After we've completed a successful migration, we'll use
                        a centralized monitoring system to assist you in
                        maintaining and managing your resources, apps, and data.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--========= More Services End ===========--> */}
      </div>
    </div>
  )
}
export default CloudAdoption
